import React from 'react';

const style = {
    margin: '0 auto',
    maxWidth: 960,
    paddingTop: 50,
    paddingBottom: 50,
};

const Content = ({children, className}) => (
    <section style={style} className={className}>
        {children}
    </section>
);

export default Content;