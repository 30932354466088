import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { graphql, StaticQuery } from 'gatsby'

import Content from '../components/content';
import Hero from '../components/hero';
import Layout from "../components/layout"
import SEO from "../components/seo"

import './index.css';

const Services = ({children}) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "pat2.png" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 180) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="services"
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      )
    }}
  />
)

const IndexPage = () => (
  <Layout>
    <SEO title="Jalkahoito ja hieronta Turussa" />
    <Hero>
      <Content>
        <p>
          Jalkahoitoa ja hierontaa <br />
          tunnelmallisissa tiloissa <br />
          <span className="red">Turussa Martinkadulla.</span>
        </p>
        <p>Varaa aika <a href="tel:0451549365"><span className="blue">045&nbsp;154&nbsp;9365</span></a></p>
      </Content>
    </Hero>
    <Content className="welcome">
        <h1>Jalkahoitoa ja hierontaa Turun Martinmäessä</h1>
        <p>Tervetuloa!</p>
        <p>Lähimmät ilmaiset parkkipaikat löytyvät Sotalaistenkadun varrelta. Myös liikuntarajoitteisen on helppo tulla vastaanotolleni. Aukioloajat ajanvarauksen mukaan, myös ilta-ajat ovat mahdollisia.</p>
        <p>Opiskelijat, varusmiehet ja -naiset sekä lotat, veteraanit ja sotainvalidit -10%</p>
    </Content>
    <Services>
      <Content>
        <h2 id="palvelut">Palvelut</h2>
        <div className="service-wrapper">
          <div>
            <h3>Jalkahoito</h3>
            <p>Jalkahoito sisältää jalkojen tutkimisen, kylvyn, kynsien leikkauksen ja ohennuksen, kovettumien poiston sekä rasvauksen ja hieronnan</p>
            <p>Tarvittaessa yksilöllinen kevennys, liimattavat tai puettavat.</p>
          </div>
          <div>
            <h3>Klassinen hieronta</h3>
            <p>Hieronta auttaa rentouttamaan lihaksia ja lieventämään lihasten jännitystiloja ja vilkastuttaa pintaverenkiertoa.</p>
            <p>Hieronnalla edistetään kuona-aineiden poistumista kudoksista sekä ravinteiden ja hapen pääsyä kudoksiin.</p>
            <p><i>Hieronta auttaa kehon ja mielen kokonaisvaltaiseen rentoutumiseen.</i></p>
          </div>
          <div>
            <h3>Intialainen päähieronta</h3>
            <p>Intialainen päähieronta on erittäin rentouttava ja miellyttävä hieronta, joka lisää aineenvaihduntaa ja verenkiertoa.</p>
            <p>Intialainen päähieronta sopii hyvin kaikenikäisille.</p>
          </div>
        </div>
        <div class="service-extra">
          <p>Myymälästä saatavilla myös hyvinvointituotteita ja lahjatarvikkeita.</p>
        </div>
      </Content>
    </Services>
    <Content className="pricing">
      <h2 id="hinnasto">Hinnasto</h2>
      <p>Ensimmäisestä hoitokerrasta -10%</p>
      <table>
        <tr>
          <th>Palvelu</th>
          <th>Kesto</th>
          <th>Hinta</th>
        </tr>
        <tr>
          <td colSpan={2}>Jalkahoito</td>
          <td>alk. 47€</td>
        </tr>
        <tr>
          <td>Klassinen hieronta</td>
          <td>30 min</td>
          <td>25€</td>
        </tr>
        <tr>
          <td></td>
          <td>45 min</td>
          <td>35€</td>
        </tr>
        <tr>
          <td></td>
          <td>60 min</td>
          <td>45€</td>
        </tr>
        <tr>
          <td></td>
          <td>90 min</td>
          <td>57€</td>
        </tr>
        <tr>
          <td>Intialainen päähieronta</td>
          <td>35 min</td>
          <td>30€</td>
        </tr>
        <tr>
          <td colSpan={2}>Kinesioteippaukset</td>
          <td>alk. 5€</td>
        </tr>
      </table>
      <br/>
      <p>Opiskelijat, varusmiehet ja -naiset sekä lotat, veteraanit ja sotainvalidit -10%</p>
    </Content>
    <div className="contact">
      <Content>
        <h2 id="yhteystiedot">Yhteystiedot</h2>
        <div className="content">
          <div>
            <p>
              <strong>Osoite</strong><br/>
              Martinkatu 7, 20810 Turku
              <br/>
              <i>Huom. Sisäänkäynti sisäpihalta</i>
            </p>
            <p>
              <strong>Sähköposti</strong><br/>
              <a href="mailto:tiina@tiinahaukijarvi.fi">tiina@tiinahaukijarvi.fi</a>
            </p>
            <p>
              <strong>Puhelin</strong><br/>
              <a href="tel:0451549365">045 154 9365</a>
            </p>
          </div>
          <div dangerouslySetInnerHTML={{__html: '<iframe title="Google Maps upote" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7872.821458380412!2d22.2574739!3d60.4418252!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf53f68c1f9635277!2sTiina%20Haukij%C3%A4rvi!5e0!3m2!1sen!2sfi!4v1580328225714!5m2!1sen!2sfi" width="400" height="300" frameborder="0" style="border:0;" allowfullscreen=""></iframe>'}}>
          </div>
        </div>
      </Content>
    </div>
  </Layout>
)

export default IndexPage
