import React from 'react';
import BackgroundImage from 'gatsby-background-image'
import { graphql, StaticQuery } from 'gatsby'

const Hero = ({children}) => (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "bg.jpg" }) {
            childImageSharp {
              fluid(quality: 50, maxWidth: 1980) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        const imageData = data.desktop.childImageSharp.fluid
        return (
          <BackgroundImage
            Tag="section"
            className="jumbotron"
            fluid={imageData}
            backgroundColor={`#040e18`}
          >
            {children}
          </BackgroundImage>
        )
      }}
    />
  )

  export default Hero;